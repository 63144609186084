

//pages
import Home from "./pages/home/Home.js";

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
