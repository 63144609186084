import React, { useState, useEffect } from 'react';

// images
import ImgHeart from "../../resources/images/heart.png";
import ImgWedding from "../../resources/images/wedding.png";
import ImgPhotoCamera from "../../resources/images/photo-camera.png";
import ImgRestaurant from "../../resources/images/restaurant.png";
import LogoNameImg from "../../resources/images/logo_name.png";
import UnsetCeremony from "../../resources/images/unsetCeremony.png";
import GooglImg from "../../resources/images/googl.png";
import YandexImg from "../../resources/images/yandex.png";

export default function Home() {
    const lng = localStorage.getItem('language');
    const [language, setLanguage] = useState(lng ? lng : "hy");

    useEffect(() => {
        const updateTimer = () => {
            const countDownDate = new Date("Sep 24, 2024 15:00:00").getTime();
            const now = new Date().getTime();
            const distance = countDownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            document.getElementById("timerDay").innerHTML = days;
            document.getElementById("timerHours").innerHTML = hours;
            document.getElementById("timerMinutes").innerHTML = minutes;
            document.getElementById("timerSeconds").innerHTML = seconds;

            if (distance > 0) {
                setTimeout(updateTimer, 1000);
            } else {
                document.getElementById("timerDay").innerHTML = "0";
                document.getElementById("timerHours").innerHTML = "0";
                document.getElementById("timerMinutes").innerHTML = "0";
                document.getElementById("timerSeconds").innerHTML = "0";
            }
        };

        updateTimer();
    }, []);

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang);
    };

    const getContent = (key) => {
        const translations = {
            hy: {
                program: "Ծրագիր",
                wedding: "Պսակադրություն",
                restaurant: "Հարսանյաց հանդիսություն",
                weddingInvitation: "ՀԱՐՍԱՆԵԿԱՆ ՀՐԱՎԻՐԱՏՈՄՍ",
                weddingIsLeft: "Հարսանիքին մնացել է",
                day: "Օր",
                hours: "Ժամ",
                minute: "Րոպե",
                second: "Վայրկյան",
                weWant: "Սիրով հրավիրում ենք Ձեզ՝ կիսելու մեզ հետ մեր կյանքի կարևոր և հիշարժան օրը",
                invitation: "Հրավեր",
                startEvent: "Միջոցառման սկիզբը",
                photoSession: "Ֆոտոսեսիա",
                zvartnotsCathedral: "Զվարթնոց տաճար",
                restaurantLiana: '"ԼԻԱՆՆԱ ԳԱՐԴԵՆ ՀՈԼԼ" ռեստորան',
                saintGayaneChurch: 'Սուրբ Գայանե եկեղեցի',
                howGetThere: 'Ինչպես հասնել',
                unsetCeremony: 'Զաքսի արարողություն',
                cityEtchmiadzin: 'ք․ Էջմիածին',
                cityVagharshapat: 'ք․ Վաղարշապատ',
                cityParakar: 'Փարաքար',
            },
            ru: {
                program: "Программа",
                wedding: "Церемония венчания",
                restaurant: "Свадебное торжество",
                weddingInvitation: "ПРИГЛАШЕНИЕ НА СВАДЬБУ",
                weddingIsLeft: "До свадьбы осталась",
                day: "День",
                hours: "Час",
                minute: "Минут",
                second: "Секунд",
                weWant: "С любовью приглашаем вас на нашу свадьбу! Мы хотим разделить с вами этот важный и незабываемый день в нашей жизни.",
                invitation: "Приглашение",
                startEvent: "Начало мероприятия",
                photoSession: "Фотосессия",
                zvartnotsCathedral: "Собор Звартноц",
                restaurantLiana: 'Ресторан "ЛИАННА ГАРДЕН ХОЛЛ"',
                saintGayaneChurch: 'Церковь Святой Гаяне в Эчмиадзине',
                howGetThere: 'Как туда добраться?',
                unsetCeremony: 'Церемония бракосочетания',
                cityEtchmiadzin: 'г․ Эчмиадзин',
                cityVagharshapat: 'г․ Вагаршапат',
                cityParakar: 'Паракар',
            },
            en: {
                program: "Program",
                wedding: "Wedding ceremony",
                restaurant: "The wedding party",
                weddingInvitation: "WEDDING INVITATION",
                weddingIsLeft: "Until the wedding, only",
                day: "Day",
                hours: "Hour",
                minute: "Minute",
                second: "Second",
                weWant: "We lovingly invite you to our wedding! We want to share this important and unforgettable day with you.",
                startEvent: "Start of the ceremony",
                photoSession: "Photoshoot",
                zvartnotsCathedral: "Zvartnots Cathedral",
                restaurantLiana: '"LIANNA GARDEN HALL" restaurant',
                saintGayaneChurch: 'St. Gayane Church of Etchmiadzin',
                howGetThere: 'How to get there?',
                unsetCeremony: 'Wedding registration',
                cityEtchmiadzin: 'Etchmiadzin',
                cityVagharshapat: 'Vagharshapat',
                cityParakar: 'Parakar',
            },
        };
        return translations[language][key];
    };

    return (
        <section>
            <div className='hr-lang-cnt'>
                <span
                    data-type="hy"
                    onClick={() => handleLanguageChange('hy')}>ARM</span>
                <div></div>
                <span
                    data-type="ru"
                    onClick={() => handleLanguageChange('ru')}>RU</span>
                <div></div>
                <span
                    data-type="en"
                    onClick={() => handleLanguageChange('en')}>ENG</span>
            </div>
            <span className={`hr-header-title ${language !== 'en' ? 'hr-other-font-fam' : ''}`}>
                {getContent('weddingInvitation')}
            </span>
            <div className="hr-section-top">
                <img src={LogoNameImg} alt={getContent('invitation')} />
            </div>
            <div className="hr-header-bottom">
                <div className="hr-header-bot-cnt">
                    <span className="hr-header-sub-title">{getContent('weddingIsLeft')}</span>
                    <div className="hr-timer">
                        <div className="hr-timer-column">
                            <span id="timerDay"></span>
                            <span>{getContent('day')}</span>
                        </div>
                        <div className="hr-timer-column">
                            <span id="timerHours"></span>
                            <span>{getContent('hours')}</span>
                        </div>
                        <div className="hr-timer-column">
                            <span id="timerMinutes"></span>
                            <span>{getContent('minute')}</span>
                        </div>
                        <div className="hr-timer-column">
                            <span id="timerSeconds"></span>
                            <span>{getContent('second')}</span>
                        </div>
                    </div>
                </div>
            </div>
            <span className='hr-weWant'>{getContent('weWant')}</span>
            <div className="hr-program">
                <span id="program" className="hr-program-title">{getContent('program')}</span>
                <div className="hr-program-box">
                    <div className="hr-program-ev-bx">
                        <div className="hr-program-ev-lf">
                            <span className="hr-program-ev-hr">14:45</span>
                            <div className="hr-program-ev-img">
                                <img src={ImgHeart} alt={getContent('invitation')} />
                                <div className="hr-program-ev-line"></div>
                            </div>
                        </div>
                        <div className="hr-program-ev-inf">
                            <span className="hr-program-ev-inf-tlt">{getContent('startEvent')}</span>
                            <span className="hr-program-ev-inf-txt">{getContent('saintGayaneChurch')}</span>
                            <span className="hr-program-ev-inf-locetion">{getContent('cityEtchmiadzin')}</span>
                            <div className='hr-program-ev-link-bx'>
                                <span className='hr-program-ev-link'
                                    target="_blank"
                                    rel="noreferrer noopener">{getContent('howGetThere')}</span>
                                <a className='hr-program-ev-link-img' href="https://www.google.com/maps/dir//%D0%A6%D0%B5%D1%80%D0%BA%D0%BE%D0%B2%D1%8C+%D0%A1%D0%B2%D1%8F%D1%82%D0%BE%D0%B9+%D0%93%D0%B0%D1%8F%D0%BD%D1%8D+575R%2B2P4+Vagharshapat/@40.1575021,44.2918411,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x406a952aa68c6009:0x8a692ec2c8be215a!2m2!1d44.2918411!2d40.1575021?entry=ttu"
                                    target="_blank"
                                    rel="noreferrer noopener"><img src={GooglImg} alt={getContent('invitation')} /></a>
                                <a className='hr-program-ev-link-img' href="https://yandex.by/maps/-/CDsQVKPW"
                                    target="_blank"
                                    rel="noreferrer noopener"><img src={YandexImg} alt={getContent('invitation')} /></a>
                            </div>
                        </div>
                    </div>
                    <div className="hr-program-ev-bx">
                        <div className="hr-program-ev-lf">
                            <span className="hr-program-ev-hr">15:00</span>
                            <div className="hr-program-ev-img">
                                <img src={UnsetCeremony} alt={getContent('invitation')} className='UnsetCeremony' />
                                <div className="hr-program-ev-line"></div>
                            </div>
                        </div>
                        <div className="hr-program-ev-inf">
                            <span className="hr-program-ev-inf-tlt">{getContent('wedding')}</span>
                            <span className="hr-program-ev-inf-txt">{getContent('saintGayaneChurch')}</span>
                            <span className="hr-program-ev-inf-locetion">{getContent('cityEtchmiadzin')}</span>
                        </div>
                    </div>
                    <div className="hr-program-ev-bx">
                        <div className="hr-program-ev-lf">
                            <span className="hr-program-ev-hr">16:30</span>
                            <div className="hr-program-ev-img">
                                <img src={ImgPhotoCamera} alt={getContent('invitation')} />
                                <div className="hr-program-ev-line"></div>
                            </div>
                        </div>
                        <div className="hr-program-ev-inf">
                            <span className="hr-program-ev-inf-tlt">{getContent('photoSession')}</span>
                            <span className="hr-program-ev-inf-txt">{getContent('zvartnotsCathedral')}</span>
                            <span className="hr-program-ev-inf-locetion">{getContent('cityVagharshapat')}</span>
                        </div>
                    </div>
                    <div className="hr-program-ev-bx">
                        <div className="hr-program-ev-lf">
                            <span className="hr-program-ev-hr">17:30</span>
                            <div className="hr-program-ev-img">
                                <img src={ImgWedding} alt={getContent('invitation')} />
                                <div className="hr-program-ev-line"></div>
                            </div>
                        </div>
                        <div className="hr-program-ev-inf">
                            <span className="hr-program-ev-inf-tlt">{getContent('unsetCeremony')}</span>
                            <span className="hr-program-ev-inf-txt">{getContent('restaurantLiana')}</span>
                            <span className="hr-program-ev-inf-locetion">{getContent('cityParakar')}</span>
                            <div className='hr-program-ev-link-bx'>
                                <span className='hr-program-ev-link'>{getContent('howGetThere')}</span>
                                <a className='hr-program-ev-link-img' href="https://www.google.com/maps/dir//%D4%BC%D5%AB%D5%A1%D5%B6%D5%B6%D5%A1+%D4%B3%D5%A1%D6%80%D5%A4%D5%A5%D5%B6+%D5%80%D5%B8%D5%AC+Parakar/@40.1649513,44.3920288,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x406abf20058b7395:0xc8881d319e6d89ca!2m2!1d44.3920302!2d40.1649412?entry=ttu"
                                    target="_blank"
                                    rel="noreferrer noopener"><img src={GooglImg} alt={getContent('invitation')} /></a>
                                <a className='hr-program-ev-link-img' href="https://yandex.by/maps/-/CDsQYN09"
                                    target="_blank"
                                    rel="noreferrer noopener"><img src={YandexImg} alt={getContent('invitation')} /></a>
                            </div>
                        </div>
                    </div>
                    <div className="hr-program-ev-bx">
                        <div className="hr-program-ev-lf">
                            <span className="hr-program-ev-hr">18:00</span>
                            <div className="hr-program-ev-img">
                                <img src={ImgRestaurant} alt={getContent('invitation')} />
                                <div className="hr-program-ev-line"></div>
                            </div>
                        </div>
                        <div className="hr-program-ev-inf">
                            <span className="hr-program-ev-inf-tlt">{getContent('restaurant')}</span>
                            <span className="hr-program-ev-inf-txt">{getContent('restaurantLiana')}</span>
                            <span className="hr-program-ev-inf-locetion">{getContent('cityParakar')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
